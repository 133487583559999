@import "@/styles/mixins";

.section {
	position: relative;
	min-height: 100vh;
	@include sm {
		position: relative;
		z-index: 1;

		margin-bottom: -1.8rem;

		background-color: #FFFFFF;
		border-radius: 0 0 2rem 2rem;
	}
}

.container {
	position: relative;
	z-index: 1;

	padding-top: 11.6rem;
	display: flex;
	justify-content: space-between;

	@include sm {
		flex-direction: column;
		padding-top: 4.4rem;
		padding-bottom: 3.4rem;
	}
}

.picture {
	position: absolute;
	z-index: -1;
	bottom: 0;
	left: -24rem;

	width: 77.5rem;
	height: 44rem;

	background-repeat: no-repeat;
	background-size: contain;
	background-position: center;

	@include sm {
		display: none;
	}
}

.title {
	margin: 0;
	max-width: 47rem;
	padding-left: 1rem;
	padding-top: 5rem;

	color: #0A2653;
	font-size: 4rem;
	font-style: normal;
	font-weight: 700;
	line-height: 110%;

	@include sm {
		padding: 0;
		margin-bottom: 4rem;

		font-size: 2.7rem;
	}
}

.main {
	max-width: 55.5rem;
	width: 100%;
}

.fishIconsList {
	display: flex;
	gap: 2rem;
	margin: 0 0 1.9rem;
	padding: 0;

	list-style: none;
	opacity: .3;

	@include sm {
		display: none;
	}
}

.fishIconItem {
	width: 5.5rem;
	height: 3rem;

	background-repeat: no-repeat;
	background-size: contain;
	background-position: center;
}

.card {
	padding: 3.6rem;
	display: flex;
	flex-direction: column;

	border-radius: 1.5rem;
	background-color: #FFFFFF;
	box-shadow: 0 .1rem 2.8rem 0 rgba(0, 0, 0, 0.03), 0 .9rem 11.1rem 0 rgba(0, 0, 0, 0.05);

	@include sm {
		padding: 2.4rem 2rem;
	}
}

.cardTitle {
	margin: 0 0 2.3rem;

	color: #0A2653;
	font-size: 2.5rem;
	font-style: normal;
	font-weight: 700;
	line-height: 110%;

	@include sm {
		font-size: 2.1rem;
	}
}

.formFields {
	gap: 2.3rem;
	display: flex;
	flex-direction: column;

	@include sm {
		gap: 1.4rem;
	}
}

.formRow {
	display: flex;
	gap: 1.3rem;

	@include sm {
		flex-direction: column;
		gap: 1.4rem;
	}
}

.submitButton {
	margin-top: 5.6rem;
	height: 5.6rem;

	@include sm {
		margin-top: 2.3rem;
	}
}
