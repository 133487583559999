@import "@/styles/mixins";

.search-panel__join {
	display: flex;
	gap: 2px;
	width: 100%;

	.input__container:first-child input {
		border-radius: 1rem 0 0 1rem;
	}

	.input__container:last-child input {
		border-radius: 0 1rem 1rem 0;
	}

	@include sm {
		flex-direction: column;
		gap: 1.6rem;
		width: 100%;

		.input__container:first-child input,
		.input__container:last-child input {
			border-radius: 1rem;
		}
	}
}
