.button {
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 0;
	width: 4rem;
	height: 4rem;

	border: none;
	border-radius: 50%;
	background-color: #14489D;

	transition: all .1s ease;

	&:hover {
		background-color: #14489D;
	}

	&.prev .icon {
		margin-right: .5rem;
	}

	&.next .icon {
		transform: scale(-1);
		margin-left: .5rem;
	}
}
