.container {
	position: absolute;

	display: flex;
	flex-direction: column;
	padding: 1.4rem 2rem;
	gap: .6rem;

	border-radius: 1rem;
	background-color: #FFFFFF;
	box-shadow: 0 .1rem 2.8rem 0 rgba(0, 0, 0, 0.03), 0 .9rem 11rem 0 rgba(0, 0, 0, 0.05);
}

.title {
	margin: 0;

	color: #0A2653;
	font-size: 1.4rem;
	font-style: normal;
	font-weight: 700;
	line-height: 140%;
}

.list {
	margin: 0;
	padding: 0;
	display: flex;
	gap: 2.4rem;
}

.value {
	margin: 0;

	color: #14489D;
	font-size: 2.5rem;
	font-style: normal;
	font-weight: 700;
	line-height: 110%;
}

.key {
	margin: 0;

	color: rgba(10, 38, 83, 0.30);
	font-size: 1.2rem;
	font-style: normal;
	font-weight: 400;
	line-height: 140%; /* 16.8px */
}
