@import "@/styles/mixins";

.section {
	min-height: 100vh;
	padding-top: 7.5rem;
	padding-bottom: 5.6rem;

	@include sm {
		padding-top: 1rem;
		padding-bottom: 2.5rem;
	}
}

.mainContainer {
	position: relative;

	min-height: 53.5rem;

	@include sm {
		padding-left: 0 !important;
		padding-right: 0 !important;
		min-height: unset;
	}
}

.map {
	position: absolute;
	top: 0;
	right: -4rem;

	height: 53.2rem;
	width: 120.5rem;

	background-repeat: no-repeat;
	background-size: contain;
	background-position: center;

	@include sm {
		position: relative;
		right: 0;

		margin-bottom: 3.5rem;
		height: 43.1rem;
		width: 100%;
	}
}

.textContainer {
	position: absolute;
	z-index: 2;
	top: 10.7rem;
	left: 3.5rem;

	@include sm {
		position: static;

		padding: 0 2rem;
		margin-bottom: 4rem;
	}
}

.title {
	margin: 0 0 1.7rem;
	max-width: 39rem;

	color: #0A2653;
	font-size: 4rem;
	font-style: normal;
	font-weight: 700;
	line-height: 110%;

	@include sm {
		margin-bottom: 1.6rem;

		font-size: 2.7rem;
	}
}

.subtitle {
	margin: 0;

	color: rgba(10, 38, 83, 0.64);
	font-size: 2rem;
	font-style: normal;
	font-weight: 400;
	line-height: 140%;

	@include sm {
		font-size: 1.6rem;
	}
}

.workWith {
	@include sm {
		display: flex;
		flex-direction: column;
		padding: 2.4rem 2rem;

		border-radius: 1.5rem;
		background-color: #FFF;
		box-shadow: 0 .1rem 2.8rem 0 rgba(0, 0, 0, 0.03), 0 .9rem 11.1rem 0 rgba(0, 0, 0, 0.05);
	}
}

.workWithTitle {
	margin: 0 0 2.4rem;

	color: #0A2653;
	font-size: 2.5rem;
	font-style: normal;
	font-weight: 700;
	line-height: 110%;

	@include sm {
		margin-bottom: 2.5rem;

		text-align: center;
		font-size: 1.9rem;
		letter-spacing: 0.019rem;
	}
}

.workWithSlider {
	position: relative;

	margin: 0;
	padding: 3rem 3rem;

	border-radius: 1.5rem;
	background-color: #FFF;
	box-shadow: 0 .1rem 2.8rem 0 rgba(0, 0, 0, 0.03), 0 .9rem 11rem 0 rgba(0, 0, 0, 0.05);

	&:after,
	&:before {
		content: '';

		position: absolute;
		z-index: 1;
		top: 0;
		bottom: 0;

		width: 2rem;

		background: linear-gradient(90deg, #FFF 0%, rgba(255, 255, 255, 0.00) 100%);
	}

	&:before {
		left: 3rem;
	}

	&:after {
		right: 3rem;
		transform: scale(-1);
	}

	@include sm {
		flex-direction: column;
		padding: 0;

		box-shadow: none;

		&:before {
			display: none;
		}

		&:after {
			display: none;
		}
	}
}

.workWithItem {
	display: inline-block;

	padding: .1rem 2.34rem;

	color: #0A2653;
	font-size: 1.8rem;
	font-style: normal;
	font-weight: 700;
	line-height: 120%;
	text-transform: uppercase;
	white-space: nowrap;

	border-right: 1px solid rgba(20, 72, 157, 0.20);

	@include sm {
		padding-left: 1rem;
		padding-right: 1rem;

		text-align: center;
		font-size: 1.6rem;

		border: none;
	}
}

.sliderButton {
	position: absolute;
	z-index: 2;

	top: 50%;

	transform: translateY(-50%);

	&.prev {
		left: -2rem;
	}

	&.next {
		right: -2rem;
	}

	@include sm {
		display: none!important;
	}
}
