@import '@/styles/mixins';

.section {
	padding-top: 10.9rem;
	padding-bottom: 8.85rem;

	color: #ffffff;
	background: url('../../../assets/background-images/main-bg-desktop.jpg');
	background-position: center;
	background-repeat: repeat;
	background-size: cover;
	border-radius: 0 0 4rem 4rem;
	min-height: calc(100vh - 6.2rem) !important;

	@include sm {
		padding-top: 7.5rem;
		padding-bottom: 3rem;
		border-radius: 0 0 3rem 3rem;

		background: url('../../../assets/background-images/main-bg-mobile.jpg');
	}
}

.container {
	display: flex;
	flex-direction: column;
	align-items: center;
	padding-left: 1rem;
	padding-right: 1rem;

	@include sm {
		padding: 0;
	}
}

.title {
	margin: 0 0 4.6rem;
	max-width: 116.7rem;

	font-size: 4rem;
	font-style: normal;
	font-weight: 700;
	line-height: 110%;
	text-align: center;

	@include sm {
		margin-bottom: 2.4rem;
		padding: 0 2rem;

		font-size: 3.2rem;
	}
}

.subtitle {
	margin: 0 0 7.85rem;
	max-width: 78.2rem;

	text-align: center;
	font-size: 2rem;
	font-style: normal;
	font-weight: 400;
	line-height: 140%;

	opacity: 0.9;

	@include sm {
		margin-bottom: 4rem;
		padding: 0 1rem;

		font-size: 1.6rem;
	}
}

.list {
	margin: 0;
	padding: 0 4.8rem;
	display: flex;
	gap: 4rem;
	width: 100%;

	list-style: none;

	@include sm {
		flex-direction: column;
		gap: 2rem;
		padding: 0 2rem;
	}
}

.card {
	overflow: hidden;

	position: relative;
	z-index: 1;

	display: flex;
	width: 100%;
	flex-direction: column;
	align-items: flex-start;
	padding: 2rem;
	min-height: 19.1rem;

	color: #14489d;

	border-radius: 1.6rem;
	background-color: #ffffff;
	box-shadow:
		0 0.1rem 2.8rem 0 rgba(0, 0, 0, 0.03),
		0 0.9rem 11rem 0 rgba(0, 0, 0, 0.05);

	@include sm {
		min-height: 15.4rem;
	}
}

.iconContainer {
	position: absolute;
	z-index: -1;
	top: 0;
	right: 0;

	padding-top: 1.6rem;
	padding-right: 1.5rem;
}

.iconBackground {
	position: absolute;
	z-index: -1;
	top: 0;
	right: 0;
}

.serviceTitle {
	margin: 0 0 0.8rem;
	max-width: 15rem;

	color: #0a2653;
	font-size: 1.6rem;
	font-style: normal;
	font-weight: 700;
	line-height: 140%;

	@include sm {
		max-width: 24rem;
	}
}

.serviceDescription {
	margin: 0 0 24px;

	color: rgba(10, 38, 83, 0.64);
	font-size: 1.2rem;
	font-style: normal;
	font-weight: 400;
	line-height: 140%;

	@include sm {
		max-width: 24.6rem;

		br {
			display: none;
		}
	}
}

.link {
	display: block;
	margin-top: auto;
	width: 100%;

	font-size: 1.2rem;
	line-height: 140%;

	@include sm {
		font-size: 1.4rem;
	}
}
