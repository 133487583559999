@import '@/styles/mixins';

.section {
	min-height: 100vh;
	padding-top: 7rem;
	padding-bottom: 5rem;

	background-color: #14489d;

	@include sm {
		padding-top: 12rem;
		padding-bottom: 5rem;
		min-height: 1950px;
	}
}

.fishes {
	margin-bottom: 1rem;

	@include sm {
		margin-bottom: 0;
	}
}

.mobileTitle {
	display: none;
	margin: 0 0 2rem;

	color: #fff;
	font-size: 2.7rem;
	font-style: normal;
	font-weight: 700;
	line-height: 110%;

	@include sm {
		display: block;
	}
}

.card {
	border-radius: 2rem;
	background: #ffffff;

	@include sm {
		background-color: transparent;
		border-radius: 0;
	}
}
