@import "@/styles/mixins";

.modal {
	overflow-y: auto;

	position: fixed;
	z-index: 15000;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;

	height: 100vh;
	display: none;
	align-items: flex-start;
	justify-content: center;
	cursor: pointer;

	background-color: rgba(10, 38, 83, 0.64);

	&--open {
		display: flex;
	}

	&__container {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 100%;
		min-height: 100%;
		padding: 5rem 1.6rem;
	}

	&__card {
		width: 100%;
		padding: 2.8rem;
		max-width: 100rem;
		margin-bottom: 5rem;

		background: #FFFFFF;
		box-shadow: 0 3.4rem 7.4rem rgba(0, 0, 0, 0.1);
		border-radius: 1rem;
		cursor: default;

		&.small {
			max-width: 74.8rem;
		}

		&.big {
			max-width: 119.1rem;
		}

		&.tiny {
			max-width: 40.7rem;
		}

		@include sm {
			padding: 2.8rem 1.6rem 2rem 1.6rem;
		}
	}

	&__title {
		font-weight: 700;
		font-size: 2.5rem;
		line-height: 2.8rem;

		@include sm {
			font-size: 2.1rem;
		}
	}

	&__close {
		padding: 0;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-shrink: 0;
		margin-left: 1rem;

		border: none;
		background: transparent;
	}
}
