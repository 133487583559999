@import "@/styles/mixins";

.section {
	padding-top: 5.2rem;
	min-height: 100vh;

	@include sm {
		position: relative;
		z-index: 2;

		padding-top: 2.5rem;
		padding-bottom: 4rem;
		margin-bottom: -1.8rem;

		background-color: #FFFFFF;
		border-radius: 0 0 2rem 2rem;
	}
}

.mainContainer {
	@include sm {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
	}
}

.title {
	margin: 0 0 2.4rem;

	color: #0A2653;
	font-size: 4rem;
	font-style: normal;
	font-weight: 700;
	line-height: 110%;

	@include sm {
		margin-bottom: 1.6rem;

		font-size: 2.7rem;
	}
}

.subtitle {
	margin: 0;
	max-width: 56rem;

	color: rgba(10, 38, 83, 0.64);
	font-size: 2rem;
	font-style: normal;
	font-weight: 400;
	line-height: 140%;

	@include sm {
		margin-bottom: 2.4rem;

		font-size: 1.6rem;
	}
}

.more {
	display: none;

	@include sm {
		display: flex;
	}
}

.background {
	position: relative;

	height: 56.2rem;
	margin-top: 1.7rem;
	padding-bottom: 8rem;

	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;

	@include sm {
		height: unset;
		padding: 0;
		margin-top: 5rem;
	}
}

.list {
	display: flex;
	margin: 0 0 7.5rem;
	padding: 0;

	list-style: none;

	@include sm {
		flex-direction: column;
		gap: 1.6rem;
		margin: 0;
	}
}

.item {
	position: relative;

	display: flex;
	flex-direction: column;
	align-items: flex-start;

	&:nth-child(1) {
		margin-top: 9.5rem;
	}

	&:nth-child(2) {
		left: 5.5rem;
		margin-top: .5rem;
	}

	&:nth-child(3) {
		left: 12.8rem;
		margin-top: 12.9rem;
	}

	&:nth-child(4) {
		left: 16.3rem;
		margin-top: -5.4rem;
	}

	@include sm {
		position: static;

		margin: 0;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		padding: 2.4rem 2rem;

		border-radius: 1.5rem;
		background-color: #FFFFFF;
		box-shadow: 0 .1rem 2.8rem 0 rgba(0, 0, 0, 0.03), 0 .9rem 11.1rem 0 rgba(0, 0, 0, 0.05);

		&:nth-child(1),
		&:nth-child(2),
		&:nth-child(3),
		&:nth-child(4) {
			margin: 0;
		}
	}
}

.iconContainer {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 12.2rem;
	height: 12.2rem;
	margin-bottom: 3rem;

	border-radius: 50%;
	background-color: #FFFFFF;
	filter: drop-shadow(0 .4rem 7.3rem rgba(0, 0, 0, 0.06)) drop-shadow(0 1.7rem 11.8rem rgba(0, 0, 0, 0.08));

	@include sm {
		width: 4rem;
		height: 4rem;
		margin-bottom: 2rem;

		border-radius: 0;
		filter: none;
	}
}

.icon {
	width: 6.4rem;
	height: 6.4rem;

	@include sm {
		width: 4rem;
		height: 4rem;
	}
}

.itemTitle {
	margin: 0 0 .8rem;

	color: #0A2653;
	font-size: 1.6rem;
	font-style: normal;
	font-weight: 700;
	line-height: 120%;

	@include sm {
		font-size: 1.8rem;
		font-weight: 600;
	}
}

.itemSubtitle {
	margin: 0;
	max-width: 24.9rem;

	color: rgba(10, 38, 83, 0.64);
	font-size: 1.6rem;
	font-style: normal;
	font-weight: 400;
	line-height: 140%;

	@include sm {
		font-size: 1.4rem;
	}
}

.actions {
	display: flex;
	gap: 2.4rem;

	@include sm {
		display: none;
	}
}

.action {
	min-height: 5.6rem;
	min-width: 13.2rem;
}
