@import "@/styles/mixins";

.container {
  width: 100%;
  max-width: 120rem;
	padding-left: 3.6rem;
	padding-right: 3.6rem;
  margin: 0 auto;

	@include sm {
		padding-left: 2rem;
		padding-right: 2rem;
	}
}
