@import 'src/styles/mixins';
@import 'src/styles/colors';
@import 'src/styles/typography';
@import 'src/styles/gaps';

.footer {
	background: url('../../assets/background-images/fishbg-footer.png');
	background-color: #14489d;
	background-repeat: repeat;
	background-position: center;
	padding: $gap-8xl 0 $gap-6xl 0;
	position: relative;

	@include sm {
		padding: $gap-l 0;
		background: url('../../assets/background-images/fishbg-blue-mobile.png');
		background-color: #14489d;
	}

	.container {
		display: flex;
		flex-wrap: wrap;
		align-items: flex-start;
		position: relative;
		z-index: 1;

		@include md {
			flex-direction: column;
		}

		@include sm {
			flex-direction: column;
			align-items: unset;
			padding: 0;
		}
	}
}

.footer__about {
	display: flex;
	width: 370px;
	align-items: center;
	margin-right: auto;

	@include lg {
		width: auto;
		margin-right: $gap-8xl;
	}

	@include md {
		width: 100%;
		margin-right: 0;
	}

	@include sm {
		width: 100%;
		flex-direction: column;
	}
}

.footer__about__logo {
	display: block;
	width: 40px;
	margin-right: $gap-m;
}

.footer__about__content {
	@include sm {
		text-align: center;
		margin-top: 20px;
	}

	span {
		display: block;
		&:nth-child(1) {
			line-height: 19px;
			color: $white;

			@include text-m;
		}

		&:nth-child(2) {
			color: $grey;
			margin-top: $gap-s;

			@include text-s;
			line-height: 14px;

			@include sm {
				margin-top: $gap-xs;
			}
		}
	}
}
.footer__navigation {
	max-width: 645px;
	flex: 1;

	@include text-s;
	line-height: 16px;

	@include md {
		margin-top: $gap-xl;
	}

	@include sm {
		margin-top: $gap-s;
	}

	ul {
		column-count: 2;
		list-style: none;
		padding: 0;
		margin: 0;

		@include sm {
			column-count: unset;
		}
	}

	li {
		margin-bottom: 12px;

		@include sm {
			text-align: center;
			margin-bottom: 6px;

			&:not(.sm) {
				display: none;
			}
		}
	}

	a {
		color: $footer-link;
		transition: color 0.2s ease;

		@media (hover: hover) {
			&:hover {
				color: $white;
			}
		}
	}
}

.footer__contacts {
	display: flex;
	width: 100%;

	@include md {
		margin-top: $gap-xl;
	}

	@include sm {
		margin-top: $gap-s;
		flex-direction: column;
	}

	a {
		transition: color 0.2s ease;
	}
}
.footer__contacts__item {
	@include sm {
		display: flex;
		justify-content: center;

		&:nth-child(1) {
			flex-direction: column;
			align-items: center;
		}
	}

	&:nth-child(2) {
		margin-top: auto;
		margin-left: auto;

		@include sm {
			margin: $gap-s 0 0 0;
		}
	}
}
.footer__contacts__phone {
	font-weight: 600;
	font-size: 18px;
	line-height: 21px;

	a {
		color: $white;

		@media (hover: hover) {
			&:hover {
				color: $footer-link;
			}
		}
	}
}

.footer__contacts__email {
	margin-top: $gap-s;

	@include text-s;

	@include sm {
		margin-top: $gap-xs;
	}

	a {
		color: $footer-link;

		@media (hover: hover) {
			&:hover {
				color: $white;
			}
		}
	}
}

.footer__contacts__socials {
	display: flex;
	align-items: center;

	a {
		display: block;
		width: 26px;

		@media (hover: hover) {
			&:hover {
				svg {
					fill: $white;
				}
			}
		}

		&:not(:last-child) {
			margin-right: 20px;
		}

		svg {
			display: block;
			height: auto;
			width: 100%;
			fill: $footer-link;
			transition: fill 0.2s ease;
		}
	}
}
