@import "@/styles/mixins";

.section {
	min-height: 100vh;
	padding-top: 18.7rem;
	padding-bottom: 5rem;

	background-position: center;
	background-size: contain;
	background-repeat: no-repeat;
	background-color: #14489D;

	@include sm {
		padding-top: 6.2rem;
		padding-bottom: 3.4rem;
		margin-bottom: -1.8rem;

		border-radius: 0 0 2rem 2rem;
	}
}

.container {
	display: flex;
	justify-content: space-between;

	@include sm {
		flex-direction: column;
	}
}

.info {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	max-width: 44.8rem;
	padding-top: 6.5rem;

	color: #FFFFFF;

	@include sm {
		padding: 0;
		margin-bottom: 4rem;
	}
}

.title {
	margin: 0 0 2.4rem;

	font-size: 4rem;
	font-weight: 700;
	line-height: 110%;

	@include sm {
		margin-bottom: 1.6rem;
		font-size: 2.7rem;
	}
}

.subtitle {
	margin: 0 0 7.2rem;

	font-size: 2rem;
	font-style: normal;
	font-weight: 400;
	line-height: 140%;

	opacity: .6;

	@include sm {
		margin-bottom: 2.4rem;
		font-size: 1.6rem;
	}
}

.moreButton {
	min-height: 5.6rem;
	min-width: 18rem;

	@include sm {
		min-height: 4.1rem;
		min-width: 15.5rem;
	}
}

.card {
	max-width: 55.5rem;
	width: 100%;
	padding: 3.6rem;

	border-radius: 1.5rem;
	background-color: #FFFFFF;
	box-shadow: 0 .1rem 2.8rem 0 rgba(0, 0, 0, 0.03), 0 .9rem 11rem 0 rgba(0, 0, 0, 0.05);

	@include sm {
		padding: 2.4rem 2rem;
	}
}

.cardTitle {
	margin: 0 0 2.3rem;

	color: #0A2653;
	font-size: 2.5rem;
	font-style: normal;
	font-weight: 700;
	line-height: 110%;

	@include sm {
		font-size: 2.1rem;
	}
}

.formFields {
	gap: 2.3rem;
	display: flex;
	flex-direction: column;

	@include sm {
		gap: 1.4rem;
	}
}

.formRow {
	display: flex;
	gap: 1.3rem;

	@include sm {
		flex-direction: column;
		gap: 1.4rem;
	}
}

.submitButton {
	margin-top: 5.6rem;
	height: 5.6rem;
	width: 100%;

	@include sm {
		margin-top: 2.3rem;
	}
}
